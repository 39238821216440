#container
    gap: 20px
    padding: 5% 0
    flex-wrap: nowrap
    
#title 
    color: white
    padding: 0
    font-weight: bolder
    font-size: 3rem
    padding: 0 5%

#works_container 
    justify-content: center
    gap: 60px

#work_item
    width: 80%
    height: 100%
    display: flex
    flex-flow: row wrap
    justify-content: center
    align-items: center
    gap: 10px
    padding: 0
    border-radius: 10px
    img
        width: 100%
        max-width: 500px
        height: 100%
        max-height: 500px
        object-fit: scale-down