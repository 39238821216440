body 
  margin: 0
  padding: 0
  font-family: "Gill Sans", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  box-sizing: border-box
  text-align: center
  background-color: #2c2c2c
  
.container
  display: flex
  align-items: center
  justify-content: center
  &.row
    flex-direction: row
    flex-wrap: wrap
  &.column
    flex-direction: column
    flex-wrap: nowrap
  &.autowrap
    flex-direction: row
    flex-wrap: wrap

@media screen and (max-width: 768px)
  .container
    &.autowrap
      flex-direction: column
      flex-wrap: nowrap 

