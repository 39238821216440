.arrow 
    position: absolute
    bottom: 60px
    left: 50%
    transform: translate(-50%, -50%)
    transform: rotate(0deg)
    span 
        display: block
        width: 20px
        height: 20px
        border-bottom: 5px solid white
        border-right: 5px solid white
        transform: rotate(45deg)
        margin: -10px
        animation: animate 2s infinite
    :nth-child(2) 
        animation-delay: -0.2s
    :nth-child(3) 
        animation-delay: -0.4s

.arrow_tiny
        left: inherit
        right: 20px

.arrow_disabled 
    span 
        animation: fadeout .8s forwards
        opacity: 0  

.hide
    display: none

@keyframes animate 
    0% 
        opacity: 0
        transform: rotate(45deg) translate(-20px, -20px)
    50% 
        opacity: 1
    
    100% 
        opacity: 0
        transform: rotate(45deg) translate(20px, 20px)
    
@keyframes fadeout 
    0% 
        opacity: 1
    100% 
        opacity: 0