#container
    min-height: 100px
    flex: 1
    gap: 10px

#text 
    color: white
    padding: 0
    font-weight: 100
    font-size: 1.5rem
    padding: 0 5%
    h1
        width: 100%
    p
        width: 100%
        font-size: 14pt