#container
    gap: 20px
    flex-wrap: nowrap
    width: 100%
#item
    max-width: 300px
    margin: 20px
    padding: 30px 10px 0

    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3)
    transition: all 0.2s ease-in-out
    &:hover
        transform: scale(1.01)
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.5)
        cursor: pointer
    #overlink
        width: 50%
    img
        width: 120px
        object-fit: cover
    
#title 
    color: white
    padding: 0
    font-weight: bolder
    font-size: 3rem
    padding: 0 5%
    

#typography
    position:absolute

@media only screen and (min-width: 767px) 
        #container
            width: 100%
            flex-wrap: wrap
            align-items: stretch
            #item
                width: 30%