#container
    background: #1e1e1e
    color : white
    justify-content: space-between
    min-height: 300px
    padding: 0 50px
    z-index: 999

#logo_container
    width: 180px
    height: 180px
    img
        width: 100%
        height: 100%
        object-fit: scale-down