#container
    flex-wrap: nowrap
    height: calc(100vh - 100px)
    padding: 60px 30px 30px 30px

#logo_container
    justify-content: space-around
    flex: 1
    height: 100%
    width: 100%
    img
        width: 100%
        height: 100%
        object-fit: scale-down

#logo_overlink
    justify-content: space-between
    margin: 30px 0
    flex: 1
    height: 100%
    width: 100%
    img
        width: 100%
        max-width: 100px
        height: 100%

#text
    flex: 1
    gap: 40px
    height: 100%
    #conexao
        font-family: 'Poppins', sans-serif
        font-size: 6rem
        color: #2bc6e8
        font-weight: 900
    h1
        font-size: 2.2rem
        color: white
        margin: -5px 0 0 0
        padding: 0
        font-weight: 400
    span
        font-size: 1.8rem
        color: white
        padding: 0
        font-weight: 100
        align-items: start

@media screen and ( min-width: 769px  )
    #text
        padding:  0 80px    
        align-items: start 
        #small 
        #large
            align-items: start

@media screen and ( max-width: 768px )
    #logo_container
        img
            max-height: 400px
            max-width: 400px
    #small
        animation: show_slogan 1s forwards

    #container
        flex-direction: column-reverse

    #text
        justify-content: start

@media screen and ( max-width: 375px ) 
    #container
        flex-wrap: nowrap
        height: 100vh
    
    #text
        justify-content: start
        #conexao
            font-size: 4rem
        h1
            font-size: 2.2rem
        span
            font-size: 1.5rem
        
@keyframes show_slogan
    0%
        opacity: 0
    100%
        opacity: 1