#container
    position: fixed
    top: 0
    z-index: 999
    justify-content: space-around
    background: #3c3c3c
    width: 100%
    height: 60px

#logo_container
    width: 180px
    height: 35px
    img
        width: 100%
        height: 100%
        object-fit: cover


#btn_row
    display: flex
    align-items: center
    justify-content: space-between
    gap: 10px

#animated_border
    animation: gradient 1.5s ease-in-out infinite alternate
    background: linear-gradient(-45deg,#0625d8,#2bc6e8)
    background-size: auto
    background-size: 300% 300%
    bottom: 0
    height: 3px
    position: absolute
    width: 100%


@keyframes gradient 
  0% 
    background-position: 0% 100%
  
  100% 
    background-position: 100% 0%
  
