#container
    gap: 20px
    flex-wrap: nowrap
    width: 100%
    
#item
    #overlink
        width: 50%
    img
        width: 180px
        object-fit: cover

#typography
    position:absolute

@media only screen and (min-width: 767px) 
    #container_features
        align-items: start
        text-align: left
        #container
            width: 70%
            flex-wrap: wrap
            #item
                width: 40%
                height: 100%

@media only screen and (max-width: 768px) 
    #item
        #overlink
            width: 100%